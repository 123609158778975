<template>
  <div>
    <a-modal width="800px" v-model="visible" :footer="null" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">查看</div>
      <div id="printDiv" ref="print">
        <a-form-model ref="form" :model="form.others" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="name" label="姓名">
            <a-input disabled v-model="form.others.name" />
          </a-form-model-item>
          <a-form-model-item prop="sex" label="性别">
            <a-select disabled v-model="form.others.sex">
              <a-select-option value="男">
                男
              </a-select-option>
              <a-select-option value="女">
                女
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="ID_image" label="照片">
            <Upload :isShow="true" :ID_image="form.others.credit_code.ID_image" :count="5" @handleChange="handleChange" />
          </a-form-model-item>
          <a-form-model-item prop="certificateType" label="证件类型">
            <a-select disabled v-model="form.others.certificateType">
              <a-select-option value="居民身份证">
                居民身份证
              </a-select-option>
              <a-select-option value="中国护照">
                中国护照
              </a-select-option>
              <a-select-option value="外国护照">
                外国护照
              </a-select-option>
              <a-select-option value="往来港澳通行证">
                往来港澳通行证
              </a-select-option>
              <a-select-option value="往来台湾通行证">
                往来台湾通行证
              </a-select-option>
              <a-select-option value="港澳居民来往内地通行证">
                港澳居民来往内地通行证
              </a-select-option>
              <a-select-option value="台湾居民来往内地通行证">
                台湾居民来往内地通行证
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="certificateNumber" label="证件编号">
            <a-input disabled v-model="form.others.certificateNumber" />
          </a-form-model-item>
          <a-form-model-item prop="post" label="岗位">
            <a-input disabled v-model="form.others.post" />
          </a-form-model-item>
          <a-form-model-item prop="status" label="状态">
            <a-select disabled v-model="form.others.status">
              <a-select-option value="在职">
                在职
              </a-select-option>
              <a-select-option value="离职">
                离职
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="phone" label="手机号">
            <a-input disabled v-model="form.others.phone" />
          </a-form-model-item>
          <div style="border-bottom: 1px solid #ccc; margin-bottom: 20px">
            <a-form-model-item prop="email" label="邮箱">
              <a-input disabled v-model="form.others.email" />
            </a-form-model-item>
          </div>
          <a-form-model-item prop="ID_image" label="健康证照片">
            <Upload :isShow="true" :ID_image="form.others.organization_code.ID_image" :count="5" @handleChange="handleChange1" />
          </a-form-model-item>
          <a-form-model-item prop="healthNumber" label="健康证编号">
            <a-input disabled v-model="form.others.healthNumber" />
          </a-form-model-item>
          <div style="border-bottom: 1px solid #ccc; margin-bottom: 20px">
            <a-form-model-item prop="healthTime" label="健康证到期时间">
              <a-range-picker disabled v-model="form.others.organization_code.healthTime" />
            </a-form-model-item>
          </div>
          <a-form-model-item prop="ID_image" label="培训证照片">
            <Upload :isShow="true" :ID_image="form.others.license_number.ID_image" :count="5" @handleChange="handleChange2" />
          </a-form-model-item>
          <a-form-model-item prop="cultivateNumber" label="培训证编号">
            <a-input disabled v-model="form.others.license_number.cultivateNumber" />
          </a-form-model-item>
          <a-form-model-item prop="cultivateLevel" label="培训证等级">
            <a-input disabled v-model="form.others.license_number.cultivateLevel" />
          </a-form-model-item>
          <a-form-model-item prop="cultivateTime" label="培训证到期时间">
            <a-range-picker disabled v-model="form.others.license_number.cultivateTime" />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div style="text-align: center">
        <a-button style="margin: 10px 30px" type="primary" v-print="printOptions" @click="printOrder">打印</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import Upload from '../../../components/Upload/index'
  import { employeeViewAdd, employeeViewEdit } from "@/api/enterprise";

  export default {
    name: "FormModal",
    props: ["visible", "form", "suppliersClassificationOptions"],
    model: { prop: "visible", event: "cancel" },
    components: {
      Upload
    },
    data() {
      return {
        printOptions: {
          id: "printDiv",
          popTitle: "正常打印",
          extraHead: "",
        },
        levelOptions: [
          { id: "0", name: "0" },
          { id: "1", name: "1" },
          { id: "2", name: "2" },
          { id: "3", name: "3" },
        ],
        uploadUrl: process.env.VUE_APP_NEW_URL + '/comapi/UploadApi',
        rules: {
          name: [
            { required: true, message: "请输入姓名", trigger: "blur" }
          ],
          sex: [
            { required: true, message: "请选择性别", trigger: "change" }
          ],
          certificateType: [
            { required: true, message: "请选择证件类型", trigger: "change" }
          ],
          healthNumber: [
            { required: true, message: "请输入证件编号", trigger: "blur" }
          ],
          post: [
            { required: true, message: "请输入岗位", trigger: "blur" }
          ],
          status: [
            { required: true, message: "请选择状态", trigger: "change" }
          ],
          phone: [
            { required: true, message: "请输入手机号", trigger: "blur" }
          ]
        },
        loading: false,
      };
    },
    methods: {
      printOrder() {
        this.$print(this.$refs.print)
      },
      handleChange(val) {
        this.$set(this.form.others.credit_code,'ID_image',val);
      },
      handleChange1(val) {
        this.$set(this.form.others.organization_code,'ID_image',val);
      },
      handleChange2(val) {
        this.$set(this.form.others.license_number,'ID_image',val);
      },
      confirm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.loading = true;
            let form = this.form;
            let func = form.id ? employeeViewEdit : employeeViewAdd;

            func(form)
                    .then(() => {
                      this.$message.success(form.id ? "修改成功" : "新增成功");
                      this.$emit(form.id ? "update" : "create");
                      this.cancel();
                    })
                    .finally(() => {
                      this.loading = false;
                    });
          }
        });
      },
      cancel() {
        this.$emit("cancel", false);
        if(!this.form.id) {
          this.$refs.form.resetFields();
        }
      },
    },
  };
</script>

<style scoped></style>
